
import styled from 'styled-components';

export const Title = styled.div`
    margin-bottom: 54px;
    font-size: 2.4rem;
`;

export const SubTitle = styled.div`
    margin-bottom: 16px;
    margin-top: 16pxx;
    font-size: 1.16rem;
`;

export const Paragraph = styled.div`
    opacity: 0.92;
    font-size: 0.96rem;
    padding: 24px 24px;
    line-height: 24px;
    text-align: justify;
    text-justify: inter-word;
`;