import React, { useEffect } from 'react';
import Footer from './Footer/footer';
import NavbarPage from './Navbar/Navbar_Page';

const PageComponent = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return <React.Fragment>
        <NavbarPage
            navclass={""}
            imglight={false}
        />
        <div style={{ padding: '54px', marginTop: '82px' }}>{children}</div>
        <Footer />
    </React.Fragment>
}

export default PageComponent;