import React, { useState } from "react";
import { Paragraph, Title } from "./page.styles";
import PageComponent from "../components/PageComponent";
import { Button, Input } from "reactstrap";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (!msg) {
      return;
    }

    setLoading(true);
    try {
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve(true);
        }, 1500)
      );
      setName("");
      setEmail("");
      setMsg("");
      setSuccess(true);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageComponent>
      <Title>CONTACTANOS</Title>
      {success && (
        <div style={{ margin: "24px" }}>
          <span style={{ color: "green", fontStyle: "italic" }}>
            Gracias por contactarte! En breve estaremos respondiendo tu mensaje.
          </span>
        </div>
      )}
      <Paragraph>
        <form id={"contactUs"} onSubmit={(e) => handlesubmit(e)}>
          <div
            style={{
              padding: "16px",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "auto",
            }}
          >
            <div style={{ margin: "16px" }}>
              <Input
                placeholder="Nombre"
                type={"text"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div style={{ margin: "16px" }}>
              <Input
                placeholder="Correo"
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div style={{ padding: "0px 16px" }}>
            <div style={{ padding: "16px" }}>
              <Input
                placeholder="Mensaje / Inquietud"
                type={"textarea"}
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
              />
            </div>
          </div>
          <div style={{ padding: "0px 16px" }}>
            <div style={{ padding: "16px" }}>
              <Button type={"submit"} form={"contactUs"} disabled={loading}>
                Enviar
              </Button>
            </div>
          </div>
        </form>
      </Paragraph>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span>
          O enviamos un correo directamente a:{" "}
          <span style={{ fontStyle: "italic", fontWeight: "700" }}>
            info@appworkcloud.com
          </span>
        </span>
        <div>
          <span>
            Direccion:{" "}
            <span style={{ fontStyle: "italic" }}>
              120 Clifton Ave Newark, NJ 09104
            </span>
          </span>
        </div>
      </div>
    </PageComponent>
  );
};

export default ContactUs;
