import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import DataTransmission from "./pages/DataTransmission";
import Index1 from "./pages/index1/index1";
import Index2 from "./pages/index2/index2";
import Index3 from "./pages/index3/index3";
import Index4 from "./pages/index4/index4";
import Index5 from "./pages/index5/index5";
import Index6 from "./pages/index6/index6";
import MissionPage from "./pages/Mission";
import MoralValuesPage from "./pages/MoralValues";
import OffersPage from "./pages/Offers";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import IndexSecond1 from "./pages2/index1/index1";
import IndexSecond2 from "./pages2/index2/index2";
import IndexSecond3 from "./pages2/index3/index3";
import IndexSecond4 from "./pages2/index4/index4";
import IndexSecond5 from "./pages2/index5/index5";
import IndexSecond6 from "./pages2/index6/index6";

const routes = [
  // { path: "/Indexsecond6", component: IndexSecond6 },
  // { path: "/indexsecond5", component: IndexSecond5 },
  // { path: "/indexsecond4", component: IndexSecond4 },
  // { path: "/indexsecond3", component: IndexSecond3 },
  // { path: "/indexsecond2", component: IndexSecond2 },
  // { path: "/indexsecond1", component: IndexSecond1 },
  { path: "/pages/about-us", component: <AboutUs /> },
  { path: "/pages/contact-us", component: <ContactUs /> },
  { path: "/pages/offers", component: <OffersPage /> },
  { path: "/pages/data-transfer", component: <DataTransmission /> },
  { path: "/pages/returns", component: <ReturnPolicy /> },
  { path: "/pages/values", component: <MoralValuesPage /> },
  { path: "/pages/mission", component: <MissionPage /> },
  { path: "/pages/terms-and-conditions", component: <TermsAndConditions /> },
  { path: "/pages/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/", component: <Index6 /> },
];

export default routes;
