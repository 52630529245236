import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import ScrollspyNav from "./scrollSpy";

//Import Stickey Header
import StickyHeader from "react-sticky-header";

import logolight from "../../assets/images/logo.svg";
import logodark from "../../assets/images/logo.svg";

const NavbarPage = ({ isStickyNav, imglight }) => {
  const [navItems, setNavItems] = useState([
    // { id: 1, idnm: "home", navheading: "Home" },
    // { id: 2, idnm: "service", navheading: "Servicios" },
    // { id: 3, idnm: "features", navheading: "Productos" },
    // { id: 5, idnm: "pricing", navheading: "Pricing" },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [topPos, setTopPos] = useState("0");
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [navCenterClass, setNavCenterClass] = useState("");
  const [prevScrollpos, setPrevScrollpos] = useState(0);

  const navigate = useNavigate();

  const handleScrollMenu = async () => {
    // let currentScrollPos = window.pageYOffset;
    // if (prevScrollpos > currentScrollPos) {
    //   setTopPos("0")
    // } else {
    //   setTopPos("-420px")
    // }
    // setPrevScrollpos(currentScrollPos)
    // if (window.innerWidth <= 768) {
    //   setNavCenterClass("")
    // } else {
    //   setNavCenterClass("navbar-nav mx-auto navbar-center")
    // }
  };

  useEffect(() => {
    setPrevScrollpos(window.pageYOffset);
    const eventKey = window.addEventListener("scroll", handleScrollMenu);

    if (window.innerWidth <= 768) {
      setNavCenterClass("");
    } else {
      setNavCenterClass("navbar-nav mx-auto navbar-center");
    }

    return () => {
      window.removeEventListener("scroll", eventKey);
    };
  }, []);

  const toggleLine = () => {
    setIsOpen((prev) => !prev);
  };

  const toggle = async () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const targetId = navItems.map((item) => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      <StickyHeader
        header={
          <Navbar
            className={`navbar navbar-expand-lg fixed-top navbar-custom ${
              isStickyNav === true ? `sticky sticky-light` : ` navbar-light`
            }`}
            id="navbar"
            style={{ top: topPos }}
          >
            <Container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <NavbarBrand
                href="/"
                className="logo"
                onClick={() => navigate("/")}
              >
                <img
                  src={imglight ? logolight : logodark}
                  alt=""
                  className="logo-light"
                  height={54}
                />
              </NavbarBrand>

              <div>
                <span
                  style={{
                    fontFamily: `'Shadows Into Light', cursive`,
                    fontWeight: "600",
                    fontSize: "1.35rem",
                  }}
                >
                  APPWORK CONDOS
                </span>
              </div>
            </Container>
          </Navbar>
        }
        stickyOffset={-100}
      ></StickyHeader>
    </React.Fragment>
  );
};

export default NavbarPage;
