import React from 'react';
import { Paragraph, SubTitle, Title } from "./page.styles"
import PageComponent from "../components/PageComponent"


const OffersPage = () => {
    return <PageComponent>
        <Title>Condiciones Generales de Promociones</Title>
        <Paragraph>Estas condiciones generales para promociones son aplicables a todas y cada una de las promociones publicadas en www.appworkcloud.com. Asímismo, todas las instrucciones, normas y condiciones de una promoción concreta forman parte de estas condiciones generales. En caso de conflicto entre las promociones generales o particulares, prevalecerán las condiciones particulares de cada oferta.</Paragraph>
        <Paragraph>Las condiciones generales de uso de la página web de Appwork, incluidos los términos y condiciones de uso y la Política de privacidad publicados, también son aplicables a las Promociones. Appwork se reserva el derecho de modificar las condiciones de sus promociones (y cualquiera de los términos de uso de Appwork) a su entera discreción, que serán publicadas en la página web.</Paragraph>
        <SubTitle>Promoción</SubTitle>
        <Paragraph>Se entiende por Promoción un derecho a un descuento (hasta el importe indicado para cada promoción) en el precio de un pedido que cumpla los requisitos publicados para cada promoción. Dicho descuento es aplicable al subtotal del importe de los productos y a los gastos de envío. Las Promociones sólo son válidas en el sitio web Appwork.com y para un pedido que cumpla las condiciones de cada promoción.</Paragraph>
        <Paragraph>Las Promociones están sujetas a disponibilidad. En concreto, Appwork se reserva el derecho de cancelar una Promoción y rechazar el disfrute de un código promocional cuando el valor total de los códigos promocionales ya disfrutados supere un determinado importe. Consulta las bases específicas de cada promoción para ver el importe en cada caso. Las Promociones no conceden el derecho a realizar un pedido concreto.</Paragraph>
        <SubTitle>Cómo beneficiarse de las Promociones</SubTitle>
        <Paragraph>Solo podrás beneficiarte de una Promoción para un pedido que cumpla los requisitos de disfrute de la misma y que realices a través de www.appworkcloud.com e introduciendo el código promocional correspondiente en el carrito de la compra. Solo podrás beneficiarte de una Promoción si estás registrado con nosotros.</Paragraph>
        <Paragraph>Las Promociones sólo pueden utilizarse en el mismo momento de realizar un pedido que cumpla los requisitos de cada promoción concreta. En ningún caso podrán aplicarse a un pedido ya realizado.</Paragraph>
        <Paragraph>Las Promociones no son acumulables para un mismo pedido. No podrá utilizarse un Código Promocional conjuntamente con ningún otro.</Paragraph>
        <Paragraph>Para beneficiarse de una Promoción, deberás introducir tu Código en el momento pertinente del proceso de compra online de un pedido que cumpla los requisitos de disfrute de la promoción. Las Promociones no tienen valor monetario y no pueden canjearse por efectivo (ni por beneficios en especie, como cupones regalo).</Paragraph>
        <Paragraph>No se podrán transferir ni serán reembolsables cualesquiera importes sobrantes tras hacer uso de una Promoción.</Paragraph>
        <Paragraph>Las Promociones sólo serán válidas durante el periodo indicado y en las fechas y para los productos especificados en cada promoción. Las Promociones pueden estar limitadas a un número máximo de descuentos, cupones o códigos promocionales.</Paragraph>
        <Paragraph>Cuando el disfrute de una Promoción esté sujeto a un requisito de gasto mínimo y respecto a la compra de determinados productos, se les comunicará cuando se emita el Código Promocional.</Paragraph>
        <Paragraph>Cuando una Promoción se haya comunicado por correo electrónico, el Código Promocional no será transferible y la dirección de correo electrónico proporcionada en el pedido deberá coincidir con la dirección de correo electrónico a la que se envió la Promoción.</Paragraph>
        <SubTitle>Cancelación, cambio y compensación de reserva de pedidos con descuentos</SubTitle>
        <Paragraph>Si cancelas un pedido no tendrás derecho al reembolso del descuento ni a que se te vuelva a ofrecer la Promoción; cada promoción se agota en el momento en que das tu consentimiento a la compra. En caso de devolución y reembolso, se te reembolsará el importe que hayas abonado por la compra y en ningún caso se te abonará, total o parcialmente, el importe del descuento.</Paragraph>
        <Paragraph>Si cambias ese pedido, no tendrás derecho a que se te reembolse el Descuento ni se te vuelva a ofrecer la Promoción; si el pedido se mantiene y las únicas modificaciones son adiciones de productos, se mantendrá el valor del Descuento. Si al modificar el pedido no cumples dejas de cumplir con los requisitos de la Promoción, no se te aplicará.</Paragraph>
        <SubTitle>Rechazo y cancelación</SubTitle>
        <Paragraph>Appwork se reserva el derecho a cancelar una Promoción o a rechazar el disfrute de una Promoción si considera razonablemente que la Promoción se está utilizando de forma ilegal, ilícita, abusiva o fraudulenta. Se considera, entre otros, un uso fraudulento, abusivo o indebido, las compras realizadas por una misma persona (física o jurídica) a través de distintas cuentas de usuario para aprovecharse más de una vez de la misma promoción.</Paragraph>
        <Paragraph>Si Appwork rechaza un descuento promocional presentado como parte de un pedido por cualquier razón, le comunicará el importe correcto del pedido antes de admitir el pago con el fin de darle la oportunidad de no seguir adelante con el pedido.</Paragraph>
        <SubTitle>Limitación de responsabilidad</SubTitle>
        <Paragraph>Appwork no aceptará responsabilidad alguna ante un cliente por las pérdidas financieras derivadas del rechazo, cancelación o retirada de una Promoción, ni por la no utilización o la imposibilidad de un cliente para usar una Promoción por cualquier razón.</Paragraph>
        <SubTitle>Legislación y Jurisdicción Aplicable</SubTitle>
        <Paragraph>Toda controversia o conflicto que pueda surgir como consecuencia de las Promociones será resuelto de acuerdo con la legislación vigente en Republica Dominicana.

            En la medida en que así lo permita la ley, las partes, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, acuerdan someterse a la jurisdicción de los Juzgados y Tribunales de la ciudad de Santo Domingo.</Paragraph>
    </PageComponent>
}

export default OffersPage;