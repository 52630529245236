import React from 'react';
import { Paragraph, SubTitle, Title } from "./page.styles"
import PageComponent from "../components/PageComponent"


const ReturnPolicy = () => {
    return <PageComponent>
        <Title>POLÍTICA DE REEMBOLSO Y DEVOLUCIONES</Title>
        <Paragraph>Tenemos una política de devolución de 30 días, lo que significa que tiene 30 días después de suscribirse para solicitar una devolución. Cualquier persona es elegible para una devolucion en caso de expresar que el sistema no le conviene o le satisface su necesidad. También necesitará el recibo o comprobante de suscripcion (puede ver en su correo electronico). Para iniciar una devolución, puede contactarnos en policies@appworkcloud.com</Paragraph>
        <Paragraph>Si se acepta su devolución, le enviaremos un correo con un formulario para que explique las razones del por que el producto no aplica para su negocio. Asi como tambien instrucciones para recibir su dinero de vuelta. Siempre puede ponerse en contacto con nosotros para cualquier pregunta de devolución en policies@appworkcloud.com</Paragraph>
    </PageComponent>
}

export default ReturnPolicy;