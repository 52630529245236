import React from 'react';
import { Paragraph, SubTitle, Title } from "./page.styles"
import PageComponent from "../components/PageComponent"


const MissionPage = () => {
    return <PageComponent>
        <Title>MISION</Title>
        <Paragraph>Appwork tiene como misión ayudar en la mejora y automatización de procesos en las áreas de finanzas, salud y educación.</Paragraph>
        <Paragraph>En suma, appwork está comprometido a mejorar la educación de sus clientes o prospectos clientes en las tres áreas de enfoque principales.</Paragraph>
    </PageComponent>
}

export default MissionPage;