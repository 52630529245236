import React from "react";
import "./App.css";
import routes from "./routes";
import {
  Route,
  Routes,
} from "react-router-dom";
//  Magnific-popup
import "./assets/css/magnific-popup.css";
//  css
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.css";
import "./assets/css/style.css";

const App = () => {
  return (
    <React.Fragment>
      <Routes>
        {routes.map((route, idx) => (
          <Route path={route.path} element={route.component} key={idx} />
        ))}
      </Routes>
    </React.Fragment>
  );
}

export default App;
