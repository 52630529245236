import React from 'react';
import { Paragraph, SubTitle, Title } from "./page.styles"
import PageComponent from "../components/PageComponent"

const AboutUs = () => {
    return <PageComponent>
        <Title>SOBRE NOSOTOS</Title>
        <Paragraph>
            Empresa desarrolladora de software como servicio, desarrollamos un software CRM Customer Relationship Management para el sector inmobiliario, nuestros clientes se suscriben a nuestra membresía de pago recurrente directamente en nuestro sitio web.
        </Paragraph>
        <Paragraph>
            Somos una empresa que se ha dedicado a escuchar las necesidades de nuestros clientes en el sector inmobiliario. En base a las mismas hemos desarrollado un sistema de gestion de personal y proyectos que permitiran a las inmobiliarias incrementar sus ventas y tener un mayor control en el flujo de las mismas, desde que empieza una nueva venta hasta el cierre de la misma.
        </Paragraph>
        <SubTitle>
            Funcionalidades principales:
        </SubTitle>
        <ul>
            <li><b>Usuarios: </b>Podras crear usuarios, manejar sus roles y permisos dentro del sistema. Aprobar / Bloquear usuarios, adjuntar documentaion a los mismos.</li>
            <li><b>Perfil de usuarios: </b>Los usuarios en el sistema podran hacer publicaciones, investigaciones, analisis y los demas usuarios podran verla e interactuar con las mismas.</li>
            <li><b>Perfil de compañia: </b>Puedes editar las configuraciones de tu compañia como por ejemplo: Las aminidades de los proyectos, las vias de marketing disponibles, las caracteristicas de los inmuebles, etc. </li>
            <li><b>Embudo de ventas: </b>Cada usuario permitido podra iniciar ventas, las mismas pasar por un flujo pre establecido donde el usuario puede ir adjuntando documentacion de los usuarios: contratos, documentos, imagenes, comprobantes de pagos, etc. Hasta finalmente cerrar la venta. Cada venta esta relacionada a un usuario en el sistema.</li>
            <li><b>Proyectos: </b>El sistema cuenta con un modulo para el manejo de proyectos, donde los adminstradores del mismo podran crear nuevos proyectos, adjuntar la documentacion a cada proyecto, compartir proyectos, y hacer busquedas avanzadas.</li>
        </ul>
        <Paragraph>
            En suma, pretendemos integrar a nuestro CRM Inmobiliario un modulo de <span style={{ fontWeight: '700' }}>Contabilidad</span> y <span style={{ fontWeight: '700' }}>Comisiones</span> que te permitira tener un mayor control de tus ingresos y del pago de comisiones a tus agentes/brokers.
        </Paragraph>
        <Paragraph>
            Recuerda que si tienes alguna duda o inquietud puedes enviarnos un correo a  <span style={{ fontStyle: 'italic' }}>info@appworkcloud.com</span> y en breve un contacto de servicio al cliente se pondra en contacto con usted.
        </Paragraph>
    </PageComponent>
}

export default AboutUs;