import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../Common/SectionTitle";
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Soporte 24 horas",
        icon: "pe-7s-exapnd2",
        description:
          "Contamos con un sistema de alarmas que nos permitiran detectar problemas en cualquiera de nuestras soluciones, tenemos ingenieros de software prestos para solucionarlos a cualquier hora del dia.",
        link: "#",
      },
      {
        title: "Nuevas funcionalidades",
        icon: "pe-7s-world",
        description:
          "Estamos constantemente actualizando nuestros sistemas para satisfacer las necesidades actuales de nuestros clientes.",
        link: "#",
      },
      {
        title: "Identidad de la marca",
        icon: "pe-7s-diamond",
        description:
          "Puedes configurar nuestros aplicativos con la identidad de tu negocio (colores, logos, nombres).",
        link: "#",
      },
      {
        title: "Dominio pre-existente",
        icon: "pe-7s-paint-bucket",
        description:
          "En appwork es posible que utilices tu propio dominio para acceder al aplicativo de tu preferencia.",
        link: "#",
      },
      {
        title: "Usabilidad",
        icon: "pe-7s-rocket",
        description:
          "Nuestros productos estan enfocados en la usabilidad y experiencia de usuario. Nos preocupamos mucho por la apariencia y la calidad de nuestros sistemas.",
        link: "#",
      },
      {
        title: "Seguridad",
        icon: "pe-7s-graph1",
        description:
          "Nos importa mantener tu informacion segura y rapida.",
        link: "#",
      },
    ],
    services1: [
      {
        title: "Digital Design",
        icon: "pe-7s-paint-bucket",
        description:
          "At vero eos et accusamus iusto odio dignissimos qui blanditiis voluptatum.",
        link: "#",
      },
      {
        title: "Solutions Business",
        icon: "pe-7s-rocket",
        description:
          "Et harum quidem rerum facilis est et expedita distinctio libero tempore soluta.",
        link: "#",
      },
      {
        title: "Dynamic Growth",
        icon: "pe-7s-graph1",
        description:
          "Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe.",
        link: "#",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        {/* SERVICE START  */}
        <section id="service" className="section position-relative">
          <Container>
            <SectionTitle
              title="Soluciones en la nube"
              description="Las soluciones de appwork son todas basadas en la nube utilizando los mas altos estandares en desarrollo de software para asegurar la integridad de tu informacion y poder ofrecer a tu negocio una solucion rapida, eficiente y eficaz."
            />
            <Row>
              <ServiceBox services={this.state.services} />
            </Row>
          </Container>
        </section>

        {/* SERVICE END  */}
      </React.Fragment>
    );
  }
}

export default Services;
