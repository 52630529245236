import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";

//Import Image
import heroBgImg from "../../assets/images/hero-6-bottom-img.png";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  startApplicationCondos = () => {
    window.open(`https://condos.appworkcloud.com/onboarding`, "_blank");
  };

  loginCondos = () => {
    window.open(`https://condos.appworkcloud.com/auth`, "_blank");
  };

  render() {
    return (
      <React.Fragment>
        <section className="hero-6-bg position-relative" id="home">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <div className="text-center hero-6-content">
                  <i className="pe-7s-rocket h1 text-purple hero-6-title-icon" />
                  <h1 className="text-dark hero-6-title font-weight-medium mb-4 mt-5 line-height-1_4">
                    Adminstración de condominios
                  </h1>
                  <p className="text-muted w-75 mx-auto mb-5">
                    Hemos desarrollado una solución en la nube para la
                    administración de condominios, focalizándonos en la
                    automatización de tareas y transparencia.
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ margin: " 8px" }}
                      onClick={() => this.loginCondos()}
                    >
                      Iniciar sesión
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ margin: " 8px" }}
                      onClick={() => this.startApplicationCondos()}
                    >
                      Regístrarme
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="container-fluid">
            <Row>
              <div className="home-bottom-shape">
                <img
                  src={heroBgImg}
                  alt="heroBgImg"
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
