import React from 'react';
import { Paragraph, SubTitle, Title } from "./page.styles"
import PageComponent from "../components/PageComponent"


const MoralValuesPage = () => {
    return <PageComponent>
        <Title>VALORES</Title>
        <SubTitle>Eficacia</SubTitle>
        <Paragraph>Tomamos decisiones ágiles y actuamos con rapidez y dinamismo</Paragraph>
        <Paragraph>Trabajamos bajo un estudio previo de análisis y necesidades reales.</Paragraph>
        <SubTitle>Innovación</SubTitle>
        <Paragraph>Buscamos activamente las ocasiones para mejorar los productos y servicios</Paragraph>
        <Paragraph>Actualizamos constantemente nuestros conocimientos y nos reciclamos profesionalmente.</Paragraph>
        <SubTitle>Transparencia</SubTitle>
        <Paragraph>Proporcionamos información continua, veraz, clara y objetiva.</Paragraph>
        <Paragraph>Facilitamos el acceso a la información necesaria para desarrollar el trabajo asignado, manteniendo una actitud accesible hacia los demás.</Paragraph>
        <SubTitle>Calidad</SubTitle>
        <Paragraph>Nos preocupamos porque todos los sistemas de appwork esten probados, tenemos un personal dedicado exclusivamente a probar nuestros aplicativos para asegurar la calidad en los mismos.</Paragraph>
    </PageComponent>
}

export default MoralValuesPage;