import React from 'react';
import { Paragraph, SubTitle, Title } from "./page.styles"
import PageComponent from "../components/PageComponent"
import { Link } from 'react-router-dom';
import logoStripe from '../assets/images/logo-stripe.png';

const DataTransmission = () => {
    return <PageComponent>
        <Title>Política de Transmisión de Datos</Title>
        <SubTitle>Políticas de seguridad para la transmisión de datos de las tarjetas</SubTitle>
        <Paragraph>A través de nuestra página web no se guardan informaciones de tarjeta de créditos o débito ya que se utilizan procesadores terceros de pago que son los que se encargan de hacer todo el proceso. Invitamos a leer nuestras <Link to={'/pages/privacy-policy'}>Políticas de Privacidad.</Link></Paragraph>
        <Paragraph>No garantizamos el funcionamiento a un 100% de la página web ni que sea interrumpido el proceso de compra de planes. Nos excluimos de toda responsabilidad con respecto a la exactitud y contenido de la información desplegada.</Paragraph>
        <Paragraph>Las únicas informaciones que pasan desde nuestra base de datos hacia la plataforma tercera son los datos del propietario, correo electrónico y monto total a pagar.</Paragraph>
        <Paragraph>Tomamos todas las medidas y precauciones razonables para proteger tu información personal y seguimos las mejores prácticas de la industria para asegurar que tu información no sea utilizada de manera inapropiada, alterada o destruida</Paragraph>
        <Paragraph>Ciframos la información de tu tarjeta de crédito utilizando la tecnología de capa de puertos seguros o Secure Sockets Layer (SSL), y la almacenamos con el cifrado AES-256. También, seguimos todos los requerimientos del PCI-DSS.</Paragraph>
        <Paragraph>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img alt="footerLogo" className="img-fluid" src={logoStripe} style={{ maxWidth: '30%' }} />
            </div>
        </Paragraph>
    </PageComponent>
}

export default DataTransmission;