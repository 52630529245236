import React, { useState } from "react";
import './contactus.css';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();

  const cleanup = () => {
    setName('')
    setEmail('')
    setPhone('')
    setMsg('')
  }

  const handleSubmit = async () => {
    console.log('onss')
    // Get data, send to server
    setLoading(true);
    await new Promise((resolve) => setTimeout(() => { resolve(true) }, [2000]));
    setLoading(false);
    cleanup();
    setSuccess('Hemos recibido tu mensaje, en breve un representante de ventas se contactara contigo.')
  }

  return (
    <React.Fragment>
      <div className="contact-wrapper">
        <div className="container contact-form">
          <div className="contact-image">
            <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact" style={{ color: '#0E96AD' }} />
          </div>
          <div className="form">
            <h3>Contactanos</h3>
            {
              success && <div style={{ width: '100%', marginBottom: '24px', display: 'flex', justifyContent: 'center', fontSize: '0.9rem', fontWeight: '200', fontStyle: 'italic', color: 'green' }}>{success}</div>
            }
            <div className="row">
              <div className="col-md-6" style={{ display: 'flex', width: '50%', flexDirection: 'column', alignItems: 'center' }}>
                <div className="form-group" style={{ padding: '16px', width: '100%' }}>
                  <input type="text" name="txtName" className="form-control" placeholder="Tu nombre *" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group" style={{ padding: '16px', width: '100%' }}>
                  <input type="text" name="txtEmail" className="form-control" placeholder="Tu correo *" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group" style={{ padding: '16px', width: '100%' }}>
                  <input type="text" name="txtPhone" className="form-control" placeholder="Tu numero *" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
                <div className="form-group" style={{ padding: '16px', width: '100%' }}>
                  <button name="btnSubmit" className="btnContact" onClick={() => handleSubmit()}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {
                        loading && <div class="spinner-grow" role="status" style={{ maxWidth: '25px', maxHeight: '25px', marginRight: '8px' }}></div>
                      }
                      {
                        !loading && <div> Enviar mensaje</div>
                      }
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <textarea name="txtMsg" className="form-control" placeholder="Tu mensaje *" style={{ width: '100%', height: '150px' }} value={msg} onChange={(e) => setMsg(e.target.value)}></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactUs;
