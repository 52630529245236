import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

//Import Images
import footerlogo from "../../assets/images/logo.svg";

import logoStripe from "../../assets/images/logo-stripe.png";

import styled from "styled-components";

const LinkWrapper = styled.a`
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  const [footerItems, setFooterItems] = useState([
    {
      title: "Productos",
      links: [
        {
          linkTitle: "CRM Inmobiliario",
          link: "https://realstate.appworkcloud.com/",
        },
      ],
    },
    {
      title: "Compañía",
      links: [
        { linkTitle: "Mision", link: "/pages/mission" },
        { linkTitle: "Valores", link: "/pages/values" },
        { linkTitle: "Sobre nosotros", link: "/pages/about-us" },
        { linkTitle: "Contactanos", link: "/pages/contact-us" },
        { linkTitle: "Status", link: "https://status.appworkcloud.com" },
      ],
    },
    {
      title: "Politicas",
      links: [
        {
          linkTitle: "Terminos y condiciones",
          link: "/pages/terms-and-conditions",
        },
        { linkTitle: "Politica de privacidad", link: "/pages/privacy-policy" },
        { linkTitle: "Devoluciones", link: "/pages/returns" },
        { linkTitle: "Envio de datos", link: "/pages/data-transfer" },
        { linkTitle: "Promociones", link: "/pages/offers" },
      ],
    },
    {
      title: "Contactos",
      text: (
        <div style={{ fontSize: "0.8rem" }}>
          <div>
            <span style={{ fontWeight: "600" }}>Direccion:</span> 120 Clifton
            Ave Newark, NJ 08104
          </div>
          <div>
            <span style={{ fontWeight: "600" }}>Telefono:</span> +1(786)245-2486
          </div>
          <div>
            <span style={{ fontWeight: "600" }}>Correo:</span>{" "}
            info@appworkcloud.com
          </div>
          <div>
            <span style={{ fontWeight: "600" }}>Horario:</span> Lunes a Viernes
            8am - 6pm (EST)
          </div>
          <div>
            <img alt="footerLogo" className="img-fluid" src={logoStripe} />
          </div>
        </div>
      ),
    },
  ]);
  const [socialIcons, setSocialIcons] = useState([
    // { icon: "remixicon-facebook-line", iconLink: "#" },
    // { icon: "remixicon-twitter-line", iconLink: "#" },
    // { icon: "remixicon-instagram-line", iconLink: "#" },
    // { icon: "remixicon-skype-line", iconLink: "#" },
  ]);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {/* FOOTER START  */}
      <footer className="pt-5 pb-4 position-relative bg-light">
        <Container className="container">
          <Row>
            <Col lg={2}>
              <div className="footer-about-content mt-4">
                <img src={footerlogo} alt="footerLogo" className="img-fluid" />
                <p className="mt-4 text-muted f-15">Mejorando tus ventas</p>
                <ul className="list-inline footer-icon mt-4">
                  {socialIcons.map((item, key) => (
                    <li className="list-inline-item h4 mr-4" key={key}>
                      <Link to={item.iconLink} className="text-purple">
                        <i className={item.icon} />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col lg={9} className="offset-lg-1">
              <div className="row">
                {footerItems.map((item, key) => (
                  <div className="col-md-3" key={key}>
                    <div className="mt-4">
                      <h5 className="text-dark footer-title font-weight-medium mb-4">
                        {item.title}
                      </h5>
                      {item.text ? (
                        item.text
                      ) : (
                        <ul className="list-unstyled footer-sub-menu">
                          {item.links.map((fLink, key) => (
                            <li className="f-15 mt-3 mb-3" key={key}>
                              {fLink.link.includes("https") ? (
                                <LinkWrapper
                                  href={fLink.link}
                                  target={"_blank"}
                                >
                                  <span className="text-muted">
                                    {fLink.linkTitle}
                                  </span>
                                </LinkWrapper>
                              ) : (
                                <LinkWrapper
                                  onClick={() => navigate(fLink.link)}
                                >
                                  <span className="text-muted">
                                    {fLink.linkTitle}
                                  </span>
                                </LinkWrapper>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <FooterLinks />
        </Container>
      </footer>

      {/* FOOTER END  */}
    </React.Fragment>
  );
};

export default Footer;
